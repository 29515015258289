import React from "react";
import { TablePagination } from "@mui/material";

const OverviewTableFooter = ({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
  return (
    <div className="divOverviewFooter">
      <TablePagination rowsPerPageOptions={[5, 10, 20, 50, 100]} component="div"
                       count={count} rowsPerPage={rowsPerPage} page={page} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange}
                       labelRowsPerPage={'Zeilen pro Seite:'} labelDisplayedRows={({ from, to, count, page })=>{return `${from}-${to} von ${count}`}} />
    </div>
  );
};

export default OverviewTableFooter;
