import {useEffect, useRef, useState} from "react";
import axios from "axios";

import useGetCaseAttributes from "./useGetCaseAttributes";

const useGetCaseDetails = (initialValues, rowData) => {
    // State variables to hold various data related to case details
    const [dspSearchResults, setDspSearchResults] = useState([]); // State for search results
    const [tipSearchResults, setTipSearchResults] = useState([]); // State for search results
    const [cspSearchResults, setCspSearchResults] = useState([]); // State for search results
    const [selectedStatus, setSelectedStatus] = useState(""); // State for selected status
    const [eingangsDatum, setEingangsDatum] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
    const [selectedLabels, setSelectedLabels] = useState([]); // State for selected labels
    const [selectedAssignee, setSelectedAssignee] = useState(""); // State for selected Assignee
    const [personData, setPersonData] = useState(initialValues); // State for person data with initial values
    const [comments, setComments] = useState([]); // State for comments
    const [dspLastMatchedDate, setDspLastMatchedDate] = useState(""); // State for last matched date
    const [tipLastMatchedDate, setTipLastMatchedDate] = useState(""); // State for last matched date
    const [cspLastMatchedDate, setCspLastMatchedDate] = useState(""); // State for last matched date
    const [dspReportCreationDate, setDspReportCreationDate] = useState(""); // State for dsp report creation date
    const [tipReportCreationDate, setTipReportCreationDate] = useState(""); // State for tip report creation date
    const [cspReportCreationDate, setCspReportCreationDate] = useState(""); // State for tip report creation date
    const [dspSelectedIdentifier, setDspSelectedIdentifier] = useState(""); // State for dsp identifier
    const [tipSelectedIdentifier, setTipSelectedIdentifier] = useState(""); // State for tip identifier
    const [cspSelectedIdentifier, setCspSelectedIdentifier] = useState(""); // State for tip identifier

    // Ref to ensure the fetch function is called only once
    const fetchCalled = useRef(false);

    // custom hooks
    const {caseDetailsLabelOptions} = useGetCaseAttributes();

    // Date converter
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    };

    const dateFormatter = (timestamp) => {
        // Create a Date object
        const date = new Date(timestamp);

        const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    // useEffect to fetch case details when rowData or labelOptions change
    useEffect(() => {
        // Function to fetch case details from API
        const getCaseDetails = async () => {
            try {
                if (!rowData || !rowData.id || fetchCalled.current) {
                    return; // Exit early if rowData or rowData.id is not available
                }

                fetchCalled.current = true; // Mark the fetch as called

                // Axios GET request to fetch case details for a specific ID
                const response = await axios.get(`/case/${rowData.id}`, {
                    headers: {withCredentials: true},
                });

                const responseData = response.data; // Response data from the server

                // Separate rows based on platformCode, 901 = DSP
                const dspRows = responseData.searchResults
                    .filter((row) => row.platformCode === 901)
                    .flatMap((row) => {
                        const candidates = row.results?.candidates || [];
                        const matchedDate = formatTimestamp(row.matchedDate);
                        setDspLastMatchedDate(matchedDate);

                        // Store the selectedIdentifier for DSP
                        const dspSelectedIdentifier = row.selectedIdentifier || null;
                        setDspSelectedIdentifier(dspSelectedIdentifier);

                        // Map over each candidate to create an array of rows
                        return candidates
                            .map((candidate) => {
                                const address = candidate.addresses?.[0] || {};
                                const location = address.location || {};

                                // Ensure the fields have non-empty values
                                const hasNonEmptyValues = candidate.id || candidate.statusCode || candidate.hitQualityIndicator || location.street || location.zipCode || location.city || location.country || candidate.addresses?.length || candidate.bdsgBlocked;

                                if (hasNonEmptyValues) {
                                    return {
                                        id: candidate.id || "",
                                        firstName: address.person?.firstName || "",
                                        lastName: address.person?.name || "",
                                        birthDate: address.person?.birthDate || "",
                                        street: location.street || "",
                                        houseNo: location.houseNumber || "",
                                        zipCode: location.zipCode || "",
                                        city: location.city || "",
                                        country: location.country || "",
                                        bdsgBlocked: candidate.bdsgBlocked || false,
                                        referenceNo: address.internalReferenceNo || "",
                                    };
                                }
                                return null; // Return null if all fields are empty
                            })
                            .filter((row) => row !== null); // Filter out null entries
                    });

                setDspSearchResults(dspRows);

                // Separate rows based on platformCode, 902 = TIP
                const tipRows = responseData.searchResults
                    .filter((row) => row.platformCode === 902)
                    .flatMap((row) => {
                        const candidates = row.results?.candidates || [];
                        const matchedDate = formatTimestamp(row.matchedDate);
                        setTipLastMatchedDate(matchedDate);

                        // Store the selectedIdentifier for TIP
                        const tipSelectedIdentifier = row.selectedIdentifier || null;
                        setTipSelectedIdentifier(tipSelectedIdentifier);

                        // Map over each candidate to create an array of rows
                        return candidates
                            .map((candidate) => {
                                const address = candidate.addresses?.[0] || {};
                                const location = address.location || {};

                                // Ensure the fields have non-empty values
                                const hasNonEmptyValues = candidate.id || candidate.statusCode || candidate.hitQualityIndicator || location.street || location.zipCode || location.city || location.country || candidate.addresses?.length || candidate.bdsgBlocked;

                                if (hasNonEmptyValues) {
                                    return {
                                        id: candidate.id || "",
                                        firstName: address.person?.firstname || "",
                                        lastName: address.person?.name || "",
                                        birthDate: address.person?.birthDate ? address.person?.birthDate.join("-") : "",
                                        street: location.street || "",
                                        houseNo: location.houseNumber || "",
                                        zipCode: location.zipCode || "",
                                        city: location.city || "",
                                        country: location.country || "",
                                        bdsgBlocked: candidate.bdsgBlocked || false,
                                        referenceNo: address.internalReferenceNo || "",
                                    };
                                }
                                return null; // Return null if all fields are empty
                            })
                            .filter((row) => row !== null); // Filter out null entries
                    });

                setTipSearchResults(tipRows);

                // Separate rows based on platformCode, 903 = CSP
                const cspRows = responseData.searchResults
                    .filter((row) => row.platformCode === 903)
                    .flatMap((row) => {
                        const candidates = row.results?.candidates || [];
                        const matchedDate = formatTimestamp(row.matchedDate);
                        setCspLastMatchedDate(matchedDate);

                        // Store the selectedIdentifier for CSP
                        const cspSelectedIdentifier = row.selectedIdentifier || null;
                        setCspSelectedIdentifier(cspSelectedIdentifier);

                        // Map over each candidate to create an array of rows
                        return candidates
                            .map((candidate) => {
                                const address = candidate.addresses?.[0] || {};
                                const location = address.location || {};

                                // Ensure the fields have non-empty values
                                const hasNonEmptyValues = candidate.id || candidate.statusCode || candidate.hitQualityIndicator || location.street || location.zipCode || location.city || location.country || candidate.addresses?.length || candidate.bdsgBlocked;

                                if (hasNonEmptyValues) {
                                    return {
                                        id: candidate.id || "",
                                        firstName: address.person?.firstname || "",
                                        lastName: address.person?.name || "",
                                        birthDate: address.person?.birthDate ? address.person?.birthDate.join("-") : "",
                                        street: location.street || "",
                                        houseNo: location.houseNumber || "",
                                        zipCode: location.zipCode || "",
                                        city: location.city || "",
                                        country: location.country || "",
                                        bdsgBlocked: candidate.bdsgBlocked || false,
                                        cspMainId: address.cspMainId || "",
                                        cspLiableId: address.cspLiable || "",
                                    };
                                }
                                return null; // Return null if all fields are empty
                            })
                            .filter((row) => row !== null); // Filter out null entries
                    });

                setCspSearchResults(cspRows);

                setSelectedStatus(responseData.statusCode || ""); // Setting selected status
                setEingangsDatum(dateFormatter(responseData.entryTs) || "");
                setSelectedCategory(responseData.categoryCode || ""); // Setting selected category
                setSelectedAssignee(responseData.caseWorkerUserId || ""); // Setting selected assignee

                setSelectedLabels(responseData.labels); // Setting selected labels state

                // Setting report creation dates based on documentTypeCode
                responseData.reportBlobs.forEach((blob) => {
                    if (blob.documentTypeCode === 221) {
                        setDspReportCreationDate(formatTimestamp(blob.creationTms));
                    } else if (blob.documentTypeCode === 223) {
                        setTipReportCreationDate(formatTimestamp(blob.creationTms));
                    } else if (blob.documentTypeCode === 225) {
                        setCspReportCreationDate(formatTimestamp(blob.creationTms));
                    }
                });

                // Setting personData with mapped labels and other rowData attributes
                setPersonData({
                    personal: {
                        gender: responseData.requestPerson?.gender || "",
                        firstName: responseData.requestPerson?.firstname || "",
                        lastName: responseData.requestPerson?.lastname || "",
                        birthDate: responseData.requestPerson?.birthDate || "",
                        birthName: responseData.requestPerson?.maidenName || "",
                        emailAddress: responseData.requestPerson?.email || "",
                    },
                    location: {
                        street: responseData.requestLocations?.[0]?.street || "",
                        houseNo: responseData.requestLocations?.[0]?.houseNo || "",
                        zip: responseData.requestLocations?.[0]?.zip || "",
                        city: responseData.requestLocations?.[0]?.city || "",
                        country: responseData.requestLocations?.[0]?.country || "",
                        locationType: responseData.requestLocations?.[0]?.locationType || "",
                    },
                    statusCode: responseData.statusCode || "",
                    categoryCode: responseData.categoryCode || "",
                    labels: responseData.labels,
                    archivId: responseData.archivId || "",
                    eingangsDatum: dateFormatter(responseData.entryTs) || "",
                });

                // Setting comments from responseData after caseBlobs
                const initialComments = responseData.comments || [];
                setComments(initialComments);
            } catch (error) {
                console.error("Error fetching case details:", error); // Logging error if fetch fails
            }
        };

        getCaseDetails(); // Calling getCaseDetails if rowData exists
    }, [rowData, caseDetailsLabelOptions]); // Dependencies for useEffect

    // Returning all states and setters needed by components using this hook
    return {
        personData,
        dspSearchResults,
        tipSearchResults,
        cspSearchResults,
        selectedStatus,
        eingangsDatum,
        selectedCategory,
        selectedLabels,
        selectedAssignee,
        comments,
        dspLastMatchedDate,
        tipLastMatchedDate,
        cspLastMatchedDate,
        dspReportCreationDate,
        tipReportCreationDate,
        cspReportCreationDate,
        dspSelectedIdentifier,
        tipSelectedIdentifier,
        cspSelectedIdentifier,
        setPersonData,
        setDspSearchResults,
        setTipSearchResults,
        setCspSearchResults,
        setSelectedStatus,
        setEingangsDatum,
        setSelectedCategory,
        setSelectedLabels,
        setSelectedAssignee,
        setComments,
        setDspReportCreationDate,
        setTipReportCreationDate,
        setCspReportCreationDate,
        setDspSelectedIdentifier,
        setTipSelectedIdentifier,
        setCspSelectedIdentifier,
    };
};

export default useGetCaseDetails; // Exporting the custom hook for use in other components
