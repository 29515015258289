import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import AuthService from "../../authServices/AuthService";
import "../../styles/styleOverviewActionContainer.css";

const SettingsMenu = () => {
  const [anchorElement, setAnchorElement] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  //logout
  const handleLogout = () => {
    AuthService.logout();
    navigate("/");
  };

  return (
    <div className="divOverviewSettings">
      <IconButton className="settingsOutlinedIcon" onClick={handleClick} aria-controls="settings-menu" aria-haspopup="true" color="inherit">
        <SettingsOutlinedIcon />
      </IconButton>
      <Menu id="settings-menu" anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={handleClose}>
        {/*<MenuItem id='version' disabled>V. {process.env.REACT_APP_VERSION}</MenuItem>*/}
        <MenuItem id="abmeldenButton" onClick={handleLogout}>
          Abmelden
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SettingsMenu;
